$roboto: 'Roboto', sans-serif;

@font-face {
  font-family: 'SF Pro Rounded';
  src: url('/fonts/SFRounded-Bold.eot');
  src: url('/fonts/SFRounded-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/SFRounded-Bold.woff2') format('woff2'),
    url('/fonts/SFRounded-Bold.woff') format('woff'),
    url('/fonts/SFRounded-Bold.ttf') format('truetype'),
    url('/fonts/SFRounded-Bold.svg#SFRounded-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Rounded';
  src: url('/fonts/SFRounded-Black.eot');
  src: url('/fonts/SFRounded-Black.eot?#iefix') format('embedded-opentype'),
    url('/fonts/SFRounded-Black.woff2') format('woff2'),
    url('/fonts/SFRounded-Black.woff') format('woff'),
    url('/fonts/SFRounded-Black.ttf') format('truetype'),
    url('/fonts/SFRounded-Black.svg#SFRounded-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
