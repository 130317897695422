header {
  // background-color: $primary;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  flex-direction: row;
  z-index: 1000;
  // @include card(1);

  .logo-container {
    // flex: 1;
    margin: 12px;
  }

  .menu-horizontal {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
  }

  .user-container {
    background-color: $primaryDark;
    color: white;
    display: flex;
    padding: 0 24px;
    height: 50px;
    align-items: center;

    h4 {
      margin: 0 12px 0 0;
    }
    span {
      background-color: $accent;
      border-radius: 10px;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      padding: 2px 5px;
      color: white;
      font-size: 0.7em;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  .user-signout {
    color: white;
    height: 50px;
    padding: 0 12px;
    font-size: 0.9em;
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .mdi {
      margin-right: 8px;
      font-size: 16px;
    }

    &:hover {
      background-color: $accent2;
    }
  }
}

.menu-dropdown {
  display: flex;
  align-items: center;
  // justify-content: center;
  padding: 0 12px;
  color: white;
  font-weight: 500;
  cursor: pointer;

  &.country {
    @include sm {
      display: none;
    }
  }

  &:hover {
    background-color: $primaryDark;

    .menu-mega-container {
      opacity: 1;
      visibility: visible;
    }
    .region-container {
      opacity: 1;
      visibility: visible;
    }
  }

  .menu-item {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    .country-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .country-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    @include transition;

    .flag {
      border: 1px solid $border;
      border-radius: 18px;
    }

    &:hover {
      background-color: $artic;
    }
  }

  .flag {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .menu-mega-container {
    background-color: white;
    color: $text;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    padding: 24px;
    z-index: -1;
    @include transition;
    @include card(3);
    opacity: 0;
    visibility: hidden;
    @include sm {
      padding: 16px 0;
    }
  }
  .region-container {
    background-color: white;
    color: $text;
    position: fixed;
    margin-left: -12px;
    top: 50px;
    min-width: 200px;
    @include transition;
    @include card(3);
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    opacity: 0;
    visibility: hidden;
  }

  .menu-inner-container {
    text-align: left;
    display: flex;
    flex-direction: row;
    @include sm {
      flex-direction: column;
      height: 550px;
      overflow: scroll;
    }
  }

  .menu-group {
    margin-bottom: 0;
    margin-right: 16px;
    display: flex;
    flex-direction: column;

    @include sm {
      margin-bottom: 12px;
    }

    &.col-1 {
      flex-basis: 20%;
    }
    &.col-2 {
      flex-basis: 40%;
    }
    &.col-3 {
      flex-basis: 20%;
    }
    &.col-4 {
      flex-basis: 20%;
    }

    &:last-child {
      margin-right: 0;
    }

    h4 {
      font-weight: 800;
      font-size: 0.95em;
      color: $text;
      padding-bottom: 6px;
      text-transform: uppercase;
      border-bottom: 3px solid $border;
      @include md {
        font-size: 0.75em;
      }
    }

    .menu-col {
      display: flex;
    }

    ul {
      list-style: none;
      flex-basis: 50%;
    }

    a {
      font-size: 0.95em;
      font-weight: 400;
      color: #656565;
      display: block;
      padding: 4px 12px;
      border-radius: 6px;
      @include transition;

      @include md {
        font-size: 0.85em;
      }

      @include sm {
        font-size: 1em;
      }

      &:hover {
        color: $text;
        background-color: $border;
      }

      .mdi {
        font-size: 16px;
        margin-right: 8px;
        color: $primary;
      }
    }
  }
}
