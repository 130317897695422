.harga {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  &.open {
    padding-left: 220px;
  }
}

.harga-body {
  display: flex;
  flex: 1;
  // display: grid;
  // grid-template-columns: 220px 1fr;
  // grid-column-gap: 0px;
  // grid-row-gap: 0px;
  // justify-items: stretch;
  // align-items: stretch;
  // height: 100vh;

  .logo-header {
    margin-left: 12px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
      flex: 1;
    }

    img {
      max-width: 100%;
      height: 25px;
    }

    .menu-burger {
      margin-right: 12px;
      cursor: pointer;
      .mdi {
        font-size: 32px;
        color: #8f8f8f;
      }

      &:hover {
        .mdi {
          color: $primary;
        }
      }
    }
  }
}

.harga-content {
  flex: 1;
  // padding-top: 50px;
  // padding-left: 220px;

  @include md {
    padding-left: 0;
  }
}

.harga-nav {
  // flex: 0 0 14em;
  width: 250px;
}

.harga-inner-content {
  text-align: left;
  margin-top: 14px;
  padding-bottom: 50px;

  @include sm {
    margin-top: 30px;
  }
}

// Layout overrides
@media only screen and (min-width: 1200px) {
  .container {
    width: inherit !important;
  }
}
@media only screen and (min-width: 992px) {
  .container {
    width: inherit !important;
  }
}
@media only screen and (min-width: 768px) {
  .container {
    width: inherit !important;
  }
}
@media only screen and (min-width: 576px) {
  .container {
    width: inherit !important;
  }
}
