$custom-radio-size: 18px;
$custom-radio-border-width: 1px;
$custom-radio-transition-speed: 100ms;
$custom-radio-inset-shadow: inset 0 0.1em 1px -0.1em rgba(0, 0, 0, 0.3);

$custom-radio-pip-color: dodgerblue;
$custom-radio-pip-size: round($custom-radio-size * 0.55);

// States
$custom-radio-unchecked-bg: white;
$custom-radio-unchecked-border: transparentize(gray, 0.55);

$custom-radio-checked-bg: mix(
  $custom-radio-unchecked-bg,
  $custom-radio-pip-color,
  60%
);
$custom-radio-checked-border: $custom-radio-pip-color;

$custom-radio-active-inset-shadow: inset 0 0 2px 3px rgba(0, 0, 0, 0.1);

$custom-radio-focus-shadow: 0 0 0 2px transparentize(dodgerblue, 0.5);

$custom-radio-disabled-bg: lighten(black, 91.8%);
$custom-radio-disabled-pip-color: lighten(black, 80%);

// Check variable values
@if ($custom-radio-size % 2 == 1) {
  @error '$custom-radio-size must be even';
}
// round to nearest even number
@if ($custom-radio-pip-size % 2 == 1) {
  $custom-radio-pip-size: $custom-radio-pip-size - 1;
}

input[type='radio'] {
  position: relative;
  display: inline-block;
  margin-right: 8px;

  width: $custom-radio-size - 2;
  height: $custom-radio-size - 2;

  border-radius: 100%;
  outline: none !important;
  -webkit-appearance: none;

  // Radio
  // -----

  &::before {
    position: relative;
    top: -$custom-radio-border-width;
    left: -$custom-radio-border-width;
    display: block;
    content: '';

    background: $custom-radio-unchecked-bg;
    border: $custom-radio-border-width solid $custom-radio-unchecked-border;
    border-radius: 100%;
    box-shadow: $custom-radio-inset-shadow;

    width: $custom-radio-size;
    height: $custom-radio-size;
  }

  &:active::before {
    box-shadow: $custom-radio-inset-shadow, $custom-radio-active-inset-shadow;
  }

  &:focus::before {
    box-shadow: $custom-radio-inset-shadow, $custom-radio-focus-shadow;
  }

  &:checked::before {
    background: $custom-radio-checked-bg;
    border-color: $custom-radio-checked-border;
  }

  &:disabled::before {
    cursor: not-allowed;
    background-color: $custom-radio-disabled-bg;
    border-color: transparentize($custom-radio-unchecked-border, 0.2);
  }

  // Radio Pip
  // ---

  &::after {
    position: relative;
    top: -($custom-radio-size / 2) - $custom-radio-border-width;
    left: ($custom-radio-size / 2) - $custom-radio-border-width;

    display: block;
    content: '';

    background: $custom-radio-pip-color;
    border-radius: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

    width: 0;
    height: 0;
  }

  &:checked::after {
    transition: all ease-in-out $custom-radio-transition-speed 0;

    top: ceil(
      -($custom-radio-size / 2) - ($custom-radio-pip-size / 2) - $custom-radio-border-width -
        0.5
    );

    left: floor(
      +($custom-radio-size / 2) - ($custom-radio-pip-size / 2) - $custom-radio-border-width +
        0.5
    );

    width: $custom-radio-pip-size;
    height: $custom-radio-pip-size;
  }

  &:disabled::after {
    background: $custom-radio-disabled-pip-color;
  }
}

.radio-group {
  margin-top: 6px;
  &.horizontal {
    display: flex;

    label {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 16px;
    }
  }

  &.box {
    border: 1px solid #eee;
    display: flex;
    border-radius: 8px;
    line-height: 1;
    padding: 8px 12px;
    label {
      color: #1a1a1a;
      text-transform: initial;
      font-size: 0.95rem;
      font-weight: 400;
      margin-bottom: 0;
      margin-right: 2rem;

      &:hover {
        color: $primary;
      }
    }
  }

  label {
    font-size: 0.95em;
    color: $text;
    font-weight: 400;
    margin: 0;
    text-transform: initial;
  }
}

// $color1: $grayLight;
// $color2: $primary;

// .radio {
//   margin: 10px 0;
//   input[type='radio'] {
//     position: absolute;
//     opacity: 0;
//     + .radio-label {
//       &:before {
//         content: '';
//         background: $color1;
//         border-radius: 100%;
//         border: 1px solid darken($color1, 25%);
//         display: inline-block;
//         width: 1.2em;
//         height: 1.2em;
//         position: relative;
//         top: 0;
//         margin-right: 1em;
//         vertical-align: top;
//         cursor: pointer;
//         text-align: center;
//         transition: all 250ms ease;
//       }
//     }
//     &:checked {
//       + .radio-label {
//         &:before {
//           background-color: $color2;
//           box-shadow: inset 0 0 0 4px $color1;
//         }
//       }
//     }
//     &:focus {
//       + .radio-label {
//         &:before {
//           outline: none;
//           border-color: $color2;
//         }
//       }
//     }
//     &:disabled {
//       + .radio-label {
//         &:before {
//           box-shadow: inset 0 0 0 4px $color1;
//           border-color: darken($color1, 25%);
//           background: darken($color1, 25%);
//         }
//       }
//     }
//     + .radio-label {
//       &:empty {
//         &:before {
//           margin-right: 0;
//         }
//       }
//     }
//   }
// }
