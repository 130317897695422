.breadcrumb {
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 12px;
  font-weight: 400;

  li {
    display: flex;
    align-items: center;
    color: darken($grayLight, 40);
    a {
      color: $primary;
      display: inline-block;
      text-decoration: none;
      font-weight: 500;

      &:hover {
        color: #83a6cf;
      }
    }

    &:before {
      content: '\F0142';
      font-family: 'Material Design Icons';
      color: rgba(0, 0, 0, 0.3);
      font-size: 18px;
      align-items: center;
      margin: 0 5px;
    }
  }
}

.breadcrumb li:first-child:before {
  display: none;
}
