// Remove padding from <Grid>
.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.offset-bottom {
  margin-bottom: 0.85rem;
}

.offset-top {
  margin-top: 0.85rem;
}
