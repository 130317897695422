.DateRangePicker {
  display: flex !important;
  div {
    flex: 1;
  }
}

.DateInput {
  width: inherit !important;
  flex: 1;
  background: transparent !important;
}

.DateInput_input__focused {
  background-color: transparent !important;
}

.DateRangePickerInput {
  // overflow: hidden;
  height: 40px;
  border-radius: 6px;
  background-color: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  display: flex !important;
  font-size: 0.95em;
  width: 100%;
  padding: 0 16px;

  &:hover {
    background-color: #e1f5fe !important;
  }
  &:focus {
    border: 1px solid darken(#e1f5fe, 15) !important;
  }
}

.DateRangePickerInput_arrow {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.DateInput_input {
  padding: 0 !important;
  height: 38px;
  border-bottom: 0 !important;
  font-size: 0.95em !important;
  font-weight: 400 !important;
  text-align: center;
}

.DateRangePickerInput_arrow {
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}

.DateRangePicker_picker {
  z-index: 2 !important;
}

.DateRangePicker_picker__openDown {
  top: 57px !important;
}

.DateInput__openDown:before {
  top: 45px !important;
}

.DateInput__openDown:after {
  top: 47px !important;
}

.SingleDatePickerInput {
  border-radius: 6px;
  background-color: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  height: 40px;
  overflow: hidden;

  .DateInput__openUp:after {
    top: -16px !important;
  }
  .DateInput__openUp:before {
    top: -14px !important;
  }
}
.SingleDatePicker_picker__openUp {
  bottom: 55px !important;
}
