.modal {
  background-color: white;
  border-radius: 7px;

  &:focus {
    outline: none;
  }

  .title {
    font-size: 1.45em;
    margin-bottom: 12px;
  }

  .modal-content {
    width: 500px;

    @include xl {
      width: 500px;
    }

    @include lg {
      width: 500px;
    }

    @include md {
      width: 400px;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.react-responsive-modal-overlay {
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 2600;
  padding: 1.2rem;
}

.react-responsive-modal-modal {
  max-width: 990px;
  min-width: 500px;
  position: relative;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 0 auto;

  border-radius: 7px;

  .title {
    font-size: 1.3em;
    font-weight: 400;
    margin-bottom: 24px;
    margin-right: 32px;
  }

  h2 {
    font-size: 1em;
  }

  .important {
    font-size: 0.85em;
    color: $red;
    margin-bottom: 24px;
  }

  .drop-column {
    display: flex;
    flex-direction: row;

    .col {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
    h4 {
      font-size: 0.95em;
      font-weight: 500;
      span {
        font-weight: 400;
        font-size: 0.8em;
        color: #888888;
      }
    }
  }

  @include sm {
    width: 100%;
    min-width: initial;
  }
  @include md {
    width: 90%;
  }
  @include lg {
    width: 90%;
  }
  // @include xl {
  //   width: 600px;
  // }
}

.react-responsive-modal-modalCenter {
  margin: auto;
}

.react-responsive-modal-closeButton {
  position: absolute;
  top: 14px;
  right: 14px;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
}

@keyframes react-responsive-modal-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes react-responsive-modal-fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
