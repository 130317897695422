@mixin transparent-bg {
  background-image: linear-gradient(45deg, #dddddd 25%, transparent 25%),
    linear-gradient(-45deg, #dddddd 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #dddddd 75%),
    linear-gradient(-45deg, transparent 75%, #dddddd 75%);
  background-size: 10px 10px;
  background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
}

@mixin transition(
  $transition-property: all,
  $transition-time: 250ms,
  $method: ease
) {
  -webkit-transition: $transition-property $transition-time $method;
  -moz-transition: $transition-property $transition-time $method;
  -ms-transition: $transition-property $transition-time $method;
  -o-transition: $transition-property $transition-time $method;
  transition: $transition-property $transition-time $method;
}

@mixin transition-multi($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

// Use like this @include transform-multi(scale(2) translate3d(0,0,0));
@mixin transform-multi($args...) {
  -webkit-transform: $args !important;
  -moz-transform: $args !important;
  -ms-transform: $args !important;
  -o-transform: $args !important;
  transform: $args !important;
}

@mixin scale($scale: 1) {
  -webkit-transform: scale($scale) !important;
  -moz-transform: scale($scale) !important;
  -ms-transform: scale($scale) !important;
  -o-transform: scale($scale) !important;
  transform: scale($scale) !important;
}

@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
  -moz-transform: translate3d($x, $y, $z);
  -o-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}

@mixin translateY($y: 1) {
  -webkit-transform: translateY($y);
  -moz-transform: translateY($y);
  -ms-transform: translateY($y);
  -o-transform: translateY($y);
  transform: translateY($y);
}

@mixin translateX($x: 1) {
  -webkit-transform: translateX($x);
  -moz-transform: translateX($x);
  -ms-transform: translateX($x);
  -o-transform: translateX($x);
  transform: translateX($x);
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@function top-shadow($depth) {
  $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
  $blur: nth(1.5 3 10 14 19, $depth) * 4px;
  $color: rgba(black, nth(0.12 0.16 0.19 0.25 0.3, $depth));

  @return 0 $primary-offset $blur $color;
}

@function bottom-shadow($depth) {
  $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
  $blur: nth(1 3 3 5 6, $depth) * 4px;
  $color: rgba(black, nth(0.24 0.23 0.23 0.22 0.22, $depth));

  @return 0 $primary-offset $blur $color;
}

@mixin card($depth) {
  @if $depth < 1 {
    box-shadow: none;
  } @else if $depth>5 {
    @warn "Invalid $depth `#{$depth}` for mixin `card`.";
  } @else {
    box-shadow: bottom-shadow($depth), top-shadow($depth);
  }
}

@mixin circle($size) {
  width: $size;
  height: $size;
  border-radius: 100%;
}

@mixin centerer($position: relative) {
  position: $position;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

@mixin font-smoothing() {
  // Font antialising on Mac OS X
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin vertical-align($position: relative, $value: 50%, $offset: -50%) {
  position: $position;
  top: $value;
  -webkit-transform: translateY($offset);
  -ms-transform: translateY($offset);
  transform: translateY($offset);
}

@mixin clearlink {
  a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

@mixin no-focus {
  outline: none;
  box-shadow: none !important;
}

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (max-width: $screen+'px') {
    @content;
  }
}
