.u-margin-top {
  margin-top: 1.875rem;
}

.u-margin-right {
  margin-right: 1.875rem;
}

.block {
  padding: 1.25rem;
}

.u-margin-bottom-s {
  margin-bottom: 0.5rem;
}

.u-position-absolute {
  position: absolute;
}

.u-position-relative {
  position: relative;
}

.accordion {
  border: 1px solid $artic;
  border-radius: 3px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid $artic;
}

.accordion__item--has-icon {
  position: relative;
}

.accordion__title {
  background-color: white;
  color: $text;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
  @include no-focus;
  @include transition;

  &:hover {
    background-color: $artic;

    .acc-btn {
      opacity: 1;
      @include scale(1);
    }
  }
}

.accordion__body {
  padding: 15px 24px;
}

.accordion__title > *:last-child,
.accordion__body > *:last-child {
  margin-bottom: 0;
}

.accordion__arrow {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 12px;
  margin-top: -3px;
  margin-right: 6px;
  margin-left: 6px;
  color: #9e9e9e;
}

.accordion__arrow::after,
.accordion__arrow::before {
  display: block;
  position: absolute;
  top: 50%;
  width: 10px;
  height: 2px;
  background-color: currentColor;
  content: '';
}

.accordion__arrow::before {
  left: 4px;
  transform: rotate(45deg);
}

[aria-expanded='true'] .accordion__arrow::before {
  transform: rotate(-45deg);
}

.accordion__arrow::after {
  right: 4px;
  transform: rotate(-45deg);
}

[aria-expanded='true'] .accordion__arrow::after {
  transform: rotate(45deg);
}

.accordion__arrow::before,
.accordion__arrow::after {
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes move-down {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(5px);
  }
  30% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes move-up {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-5px);
  }
  30% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.accordion__title--animated:hover .accordion__arrow {
  animation-name: move-down;
  animation-duration: 1.5s;
}

.accordion__title--animated[aria-expanded='true']:hover .accordion__arrow {
  animation-name: move-up;
  animation-duration: 1.5s;
}

.accordion__body {
  display: block;
  animation: fadein 0.35s ease-in;
}

.accordion__body--hidden {
  display: none;
  opacity: 0;
  animation: fadein 0.35s ease-in;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// custom
.accordion-container {
  text-align: left;
  border-radius: 8px;
  overflow: hidden;
  border: 6px solid $artic;

  .acc-inner {
    border: 4px solid darken($artic, 5);
    overflow: hidden;
    border-radius: 8px;
    margin-left: 50px;
  }

  .acc-btn {
    background-color: $green;
    color: white;
    height: 28px;
    border-radius: 15px;
    padding: 0 12px 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 0.85em;
    font-weight: 500;
    margin-right: 6px;
    opacity: 0;
    @include transition;
    @include scale(0.8);

    .mdi {
      margin-right: 5px;
    }

    &:hover {
      background-color: $greenDark;
    }
    &:active {
      background-color: $greenLight;
    }
  }
}

.acc-title-container {
  display: flex;
  position: relative;
  align-items: center;

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
    flex: 1;
  }

  &.inner {
    padding-left: 10px;

    h3 {
      font-size: 1rem;
    }
  }

  .accordion__arrow {
    color: #9e9e9e;
  }

  .acc-icon-container {
    margin-right: 15px;
    background-color: white;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    .acc-icon {
      height: 40px;
    }
  }
}

.acc-inner-body {
  border: 4px solid darken($artic, 12);
  border-radius: 8px;
  overflow: hidden;
  margin-left: 60px;

  .acc-inner-item {
    font-size: 0.95rem;
    padding: 10px 24px;
    border-bottom: 1px solid $artic;
    cursor: pointer;
    display: flex;
    align-items: center;
    @include transition;

    span {
      flex: 1;
    }

    &:hover {
      background-color: $artic;

      .acc-btn {
        opacity: 1;
        @include scale(1);
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}
