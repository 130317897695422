.drawer-container {
  position: relative;
  margin-top: 24px;
  padding: 0 24px 90px 24px;

  h2 {
    line-height: 100%;
    font-size: 2.3rem;
    font-weight: 700;
    display: flex;
    align-items: center;

    span {
      display: inline-block;
      margin-left: 8px;
      background-color: #eeeeee;
      font-size: 1rem;
      padding: 6px 10px;
      border-radius: 8px;
      line-height: 100%;
    }
  }
  p {
    font-size: 0.95rem;
    color: #888888;
  }

  .input-drawer {
    label {
      line-height: 120%;
      font-size: 0.8rem;
      text-transform: uppercase;
      font-weight: 500;
      color: $primary;

      span {
        color: #666666;
        text-transform: none;
        font-weight: 400;
      }
    }

    .containerInput {
      margin-bottom: 0.4rem;
    }

    .inline {
      display: flex;
      .containerInput {
        margin-bottom: 0.8rem;
        input {
          width: 100%;
        }

        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .input-drawer-column {
    display: flex;
    label {
      line-height: 120%;
      font-size: 0.8rem;
      text-transform: uppercase;
      font-weight: 500;
      color: $primary;

      span {
        color: #666666;
        text-transform: none;
        font-weight: 400;
      }
    }

    .column {
      flex: 1;
    }

    //   dropzone
    .dropzone {
      min-height: 100px;
      padding: 8px;

      .mdi {
        font-size: 32px;
      }

      .info {
        h4 {
          font-size: 0.9rem;
        }
      }
    }
  }

  .checkbox-inline {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -20px;

    .input {
      flex-basis: 50%;
    }
  }

  .drawer-card {
    border: 4px solid $artic;
    // background-color: $artic;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 0.8rem;
    .row-title {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0.4rem;
    }

    .input-drawer {
      margin-bottom: 0.8rem;
    }
  }

  .answer-container {
    margin: 1em 0;
  }

  .answer-group {
    flex-direction: row;
    display: flex;
    // align-items: center;
    margin-top: 8px;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    .containerInput {
      margin-bottom: 8px !important;
    }

    .answer-locale {
      flex: 1;
    }

    .value {
      margin-left: 8px;
    }

    .containerInput {
      margin-bottom: 0;
      flex: 1;
    }

    .radiocheck {
      width: 20px;
      height: 20px;
      border: 1px solid darken($gray, 5);
      background-color: $gray;
      border-radius: 4px;
      margin-right: 12px;
      margin-top: 10px;

      &.single {
        border-radius: 12px;
      }
    }
  }

  .drawer-table {
    width: 100%;

    .drawer-row {
      margin-bottom: 8px;
      border-bottom: 1px solid $border;

      &:nth-child(even) {
        background-color: $artic;
      }

      th {
        vertical-align: top;
        padding-top: 12px;
        span {
          display: block;
          font-size: 0.8em;
          font-weight: 400;
          color: #888;
        }
      }
      td {
        padding: 10px 24px 4px 0;
      }
    }
  }
}
.drawer-btn-container {
  background-color: #ffffff;
  border-top: 1px solid $border;
  padding: 12px 24px;
  position: fixed;
  bottom: 0;
  width: 500px;
  display: flex;
  justify-content: space-between;

  .del-container {
    flex: 1;
  }

  .btn {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
