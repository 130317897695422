.input-row {
  display: flex;

  .label {
    flex: 1;
    font-size: 0.95rem;
  }

  .inline {
    flex: 0.2 1;
    text-align: right;
    margin-right: 24px;
    line-height: 120%;
    margin-top: 10px;
  }

  h3,
  label {
    margin: 0 0 0.2rem 0;
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: $primary;

    span {
      color: #9c9c9c;
    }
  }

  .column {
    flex: 1;
    margin-right: 8px;
    margin-bottom: 1.6rem;

    h4 {
      font-weight: 400;
      color: #1a1a1a;
      font-size: 0.8rem;
      text-transform: uppercase;

      span {
        color: #888888;
      }
    }

    &.survey {
      label {
        display: block;
        margin-bottom: 0.4em;
      }
    }

    &.no-margin {
      margin-bottom: 0;
    }

    &.small-margin {
      margin-bottom: 12px;
    }

    &:last-child {
      margin-right: 0;
    }

    .dropdownContainer {
      &.no-margin {
        margin-bottom: 0;
      }
    }

    .react-datetime-picker {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }
}

.column {
  flex: 1;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  .dropdownContainer {
    &.no-margin {
      margin-bottom: 0;
    }
  }
}

.input-group {
  margin-bottom: 1rem;

  label {
    margin: 0 0 0.2rem 0;
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: $primary;

    .info {
      color: #9c9c9c;
      font-size: 0.8rem;
    }
  }

  &.contain {
    position: relative;
    background-color: white;
    padding: 24px;
    border-radius: 6px;
    text-align: left;
    margin-bottom: 1.6rem;
    @include card(1);

    &.no-padding {
      padding: 0 !important;
    }

    h3:first-child {
      text-transform: inherit;
      font-size: 1.2em;
      font-weight: 400;
      line-height: 100%;
      color: #1a1a1a;
      margin: -6px -24px 24px -24px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 16px;
      padding-left: 24px;
      text-align: left;

      .compulsory {
        color: #e53935;
      }

      &.secondary {
        border-bottom: 0;
        border-top: 1px solid #eeeeee;
        padding-top: 16px;
        padding-bottom: 0;
        margin-top: 16px;
      }

      span {
        font-size: 0.8em;
        color: lighten(#1a1a1a, 40);
        padding-left: 4px;
      }
    }

    .sub-contain {
      border: 2px solid $artic;
      margin-bottom: 16px;
      border-radius: 6px;
      padding: 0 16px 8px 16px;

      &:last-child {
        margin-bottom: 0;
      }

      h3 {
        font-size: 0.8rem;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 0;
        color: $primary;
        border: none;
        padding-bottom: 4px;
      }

      h5 {
        font-weight: 500;
        font-size: 0.85em;
        background-color: $artic;
        padding: 6px 12px;

        text-transform: uppercase;
        margin: 0 -16px 16px;

        span {
          font-weight: 400;
          text-transform: initial;
          color: rgba(0, 0, 0, 0.5);
          margin-left: 8px;
        }
      }

      &.top {
        margin-top: 1rem;
        span {
          margin-left: 0;
        }
      }
    }

    .title-btn-container {
      border-bottom: 0;
      border-top: 1px solid #eeeeee;
      padding: 24px 24px 0;
      margin: 0 -24px 24px -24px;
      display: flex;
      align-items: center;

      h3 {
        border: none;
        margin: 0;
        padding: 0;
        display: inline-block;
        flex: 1;
      }
    }
  }

  .misc {
    font-size: 0.9em;
    color: $text;
    margin-bottom: 0.8rem;
  }

  h3 {
    margin: 0 0 0.2rem 0;
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: $primary;

    span {
      color: #9c9c9c;
    }
  }

  &.column {
    margin-left: -8px;

    &:last-child {
      margin-right: -8px;
    }
  }

  .column {
    flex: 1;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  // overwrite InputText margin
  .containerInput {
    margin-bottom: 0.8rem;
  }

  .info {
    font-size: 0.9em;
    line-height: 110%;
    color: $text;
  }

  &.resync {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $grayLight;
    padding: 12px;
    border-radius: 6px;

    h3 {
      border-bottom: none !important;
      padding-bottom: 0 !important;
      margin: 0px 0 8px -24px !important;
    }

    h4 {
      margin: 0;
      font-weight: 500;
      font-size: 1.1rem;
    }

    .col-left {
      flex: 1;
      margin-right: 12px;
    }

    .col-right {
      justify-content: flex-end;
      align-items: flex-end;
      display: flex;
    }
  }
}

// Color picker
.input-color {
  width: 100% !important;
  border: 1px solid $grayLight;
}

.input-mask {
  height: 40px;
  width: 100%;
  border-radius: 6px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  flex: 1;
  font-size: 0.95em;
  @include no-focus;
  @include transition(all, 250ms, ease);

  @include placeholder {
    color: rgba(0, 0, 0, 0.25);
  }

  &.withDate {
    margin-left: 8px;
    flex: 0;
    width: 70px;
  }

  &.time {
    text-align: left;
    padding-left: 12px;
  }

  &:hover {
    background-color: #e1f5fe;
  }

  &:focus {
    border: 1px solid darken(#e1f5fe, 15);
  }

  &.disabled {
    background-color: #eeeeee;
    border: 1px solid #eeeeee;
  }

  &.alert {
    border: 1px solid #ee1150;
  }
}

.input-row-basic {
  display: flex;
  text-align: left;

  .dropdownContainer {
    flex: 1;
    margin-right: 8px;

    &:last-child {
      margin: 0;
    }
  }

  .btn {
    margin-left: 8px;
  }
}

.input-text-group {
  display: flex;
  text-align: left;
  margin-bottom: 0.8rem;

  &.no-margin {
    margin-bottom: 0;
  }

  label {
    flex: 0.2;
    text-align: right;
    margin-right: 24px;
    line-height: 120%;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 0.65rem;
    color: $primary;
  }

  .column {
    flex: 1;

    &.no-margin {
      margin-bottom: 0;
    }
  }
}

.input-text {
  display: flex;
  text-align: left;

  label {
    flex: 0.2;
    text-align: right;
    margin-right: 24px;
    line-height: 120%;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 0.65rem;
    color: $primary;
    span {
      font-size: 0.7rem;
      color: #888888;
      display: block;
      text-transform: none;
    }
  }

  .column {
    flex: 1;
    margin-bottom: 0.8em;

    &.no-margin {
      margin-bottom: 0;
    }
  }
}

.input-dropdown-group {
  display: flex;
  text-align: left;
  margin-bottom: 0.8rem;

  label {
    flex: 0.2;
    text-align: right;
    margin-right: 24px;
    line-height: 120%;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 0.65rem;
    color: $primary;
  }
  .input-drop-container {
    flex: 1;
    display: flex;
  }
}

.input-checkbox-group {
  display: flex;
  text-align: left;
  margin-bottom: 0.8rem;

  label {
    flex: 0.2;
    text-align: right;
    margin-right: 24px;
    line-height: 120%;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 1rem;
    color: $primary;

    .info {
      text-transform: none;
      color: #999;
    }
  }

  .checkbox-container {
    flex: 1;

    &.row {
      flex-direction: row;
    }
  }

  .checkbox-group {
    border: 1px solid $border;
    padding: 0 12px 6px 12px;
    border-radius: 6px;
    margin-top: 0;
    label {
      margin-top: 1rem;
      text-transform: initial;
      color: $text;
      font-weight: 400;
      font-size: 0.9em;
    }
    &.inline {
      display: flex;
    }
  }

  &.inline {
    display: flex;

    .radio-group {
      flex: 1;

      label {
        margin: 0 2rem 0 0;
      }
    }
  }

  .column {
    .radio-group {
      display: inline-flex;
    }
  }

  &.column {
    flex-direction: column;
    label {
      flex: none;
      text-align: left;
      margin-bottom: 0.4rem;
      margin-top: 0;
    }

    .checkbox-group {
      padding: 12px 6px 6px 12px;
    }
  }
}

.input-cascader {
  height: 40px;
  border-radius: 6px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 12px;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 0.95em;
  @include no-focus;
  @include transition(all, 250ms, ease);
  cursor: pointer;

  @include placeholder {
    color: rgba(0, 0, 0, 0.25);
  }

  &:hover {
    background-color: #e1f5fe;
  }

  &:focus {
    border: 1px solid darken(#e1f5fe, 15);
  }
}

.input-color-swatch {
  display: flex;
  margin-bottom: 0.4rem;
  align-items: center;

  label {
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 0.2rem;
    color: $primary;
    line-height: normal;
    flex: 0.2 1;
    text-align: right;
    margin-right: 24px;
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .swatch-container {
    flex: 1;

    .color-swatch {
      display: flex;
    }
  }

  .inline {
    flex: 1;
    display: flex;
    margin-top: 5px;
  }
}

.input-number {
  border: 1px solid $border;
  padding: 0 8px;
  height: 40px;
  border-radius: 6px;
  background-color: white;
  font-size: 0.95em;
  outline: none;
}

.input-select {
  margin-bottom: 0.8rem;

  &.inline {
    display: grid;
    grid-template-columns: minmax(80px, 100px) 1fr;
    align-items: center;
  }

  label {
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;
    color: $primary;
    line-height: normal;
    text-align: right;
    margin-right: 24px;
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.input-date {
  display: flex;
  flex-direction: column;
}

.csv-input {
  background-color: #fff;
  border: 1px solid $green;
  border-radius: 6px;

  label {
    color: $green !important;
    padding: 8px 16px;
    cursor: pointer;
    display: block;
    font-size: 0.9em;
    margin: 0 !important;
  }

  &:hover {
    background-color: #f5f5f5;
  }
}
