@import '../../Themes/scss/colors';
@import '../../Themes/scss/mixins';

.container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;

  .title {
    font-size: 0.85rem;
    margin-top: 6px;
    color: darken($gray, 20);
  }

  .loading {
    animation: rotate 2s linear infinite;
    z-index: 2;
    width: 60px;
    height: 60px;

    & .path {
      stroke: $primary;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
}
