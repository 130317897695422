.dnd-container {
  margin-top: 20px;
}

.dnd-list {
  user-select: none;
  margin: 5px 0;
  position: relative;

  &:hover {
    .btn-close {
      opacity: 1;
      @include scale(1);
    }
  }

  .btn-close {
    width: 24px;
    height: 24px;
    background-color: $red;
    color: white;
    display: flex;
    line-height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    opacity: 0;
    @include scale(0.5);
    @include transition;

    &:hover {
      background-color: darken($red, 20);
    }
  }
}

.dnd-item {
  display: flex;
  border: 1px solid $artic;
  background-color: white;
  border-radius: 6px;
  padding: 6px;
  align-items: center;
  @include transition;

  &:hover {
    border: 1px solid transparentize($primary, 0.5);
  }

  h2 {
    font-size: 1.05em;
    font-weight: 400;
    margin: 0 0 0 10px;
  }

  .thumbnail {
    max-width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dnd-help {
  .dnd-item {
    border: 1px solid transparentize($primary, 0.5);
    cursor: grabbing;
  }
}

.dnd-override {
  .dnd-item {
    pointer-events: auto !important;
  }
}
