.harga-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 30px;
  font-size: 0.85em;
  border: 1px solid #f0f0f0;

  thead {
    background-color: #f7f9fb;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;

    th {
      vertical-align: middle;
      font-size: 0.8rem;
      color: #7a92a5;
    }
  }

  tr {
    border-bottom: 1px solid $artic;
    @include transition;
    &:hover {
      background-color: darken(#f7f9fb, 1);
    }
    &.update {
      background-color: #fff9c4;
    }
    &.new {
      background-color: #b9f6ca;
    }
    &.delete {
      background-color: #ffebee;
    }
  }
  td,
  th {
    padding: 10px;
    text-align: left;
  }

  &.tight {
    td,
    th {
      padding: 3px 9px;
    }
  }
}
@include sm {
  .harga-table {
    font-size: 0.95em;
    line-height: 110%;

    thead {
      display: none;
    }
    td {
      text-align: right;
      // display: block;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;

      &:after {
        // float: left;
        text-align: left;
        content: attr(data-title);
        font-weight: 600;
        margin-right: 12px;
        flex: 1;
      }
    }
  }
}
