// Colors
$primary: #275ac7;
$primaryDark: #1e4caf;

$accent: #06c6e3;
$accent2: #ff6757;

$yellow: #fdd835;
$grayLight: #eeeeee;
$gray: #eceff1;
$amber: #ffc107;

$green: #3bb44a;
$greenDark: darken($green, 5);
$greenLight: lighten($green, 5);

$red: #f44336;
$purple: #673ab7;
$artic: #f0f4f7;

// UI
$bg: #f8f8f8;
$text: #2b3f5c;
$border: rgba(0, 0, 0, 0.1);
$disable: rgba(0, 0, 0, 0.05);
