.react-autosuggest__outercontainer {
  flex: 1;
  label {
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 0.2rem;
    color: $primary;
  }
}

.append {
  display: flex;
  flex-direction: row;

  // .btn {
  //   margin-top: 24px;
  // }

  .react-autosuggest__container {
    flex: 1;
    margin-right: 8px;
  }
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  margin-bottom: 0.8rem;
  height: 40px;
  border-radius: 6px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 12px;
  width: 100%;
  font-family: $roboto;
  font-size: 0.95em;
  -webkit-appearance: none;
  @include no-focus;
  @include placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
  &:hover {
    background-color: #e1f5fe;
  }
  &:focus {
    border: 1px solid darken(#e1f5fe, 15);
  }
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input::-ms-clear {
  display: none;
}

.react-autosuggest__input--open {
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  border-radius: 6px;
  top: 40px;
  width: 100%;
  background-color: #fff;
  z-index: 2;
  overflow-y: auto;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.14);
  max-height: 225px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding-top: 0;
  padding-left: 24px;
  padding-right: 20px;
  height: 40px;
  min-height: 40px;
  line-height: 40px;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
}

.react-autosuggest__suggestion--highlighted {
  background-color: #eaf7ff;
}
