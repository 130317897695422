.product-list {
  // display: flex;
  // flex-wrap: wrap;
  // margin: 0 -5px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 0.5rem;
  justify-content: space-between;

  .product-item {
    padding: 6px;
    border-radius: 6px;
    border: 1px solid $grayLight;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    @include transition;

    &.selected {
      border: 2px solid $yellow;

      &:hover {
        border: 2px solid $yellow;

        .overlay-panel {
          background-color: transparentize($yellow, 0.1);
          border-radius: 3px;
        }
      }
    }

    &:hover {
      border: 1px solid transparentize($primary, 0.5);

      .overlay-panel {
        .btn-overlay {
          @include scale(1);

          &:hover {
            @include scale(0.9);
          }

          &:active {
            @include scale(0.8);
          }
        }
      }
    }

    h2 {
      font-weight: 500;
      margin: 0;
      font-size: 0.8em;
      line-height: 120%;
    }

    .btn-action-container {
      position: absolute;
      top: 0;
      right: 0;
    }

    .overlay-panel {
      background-color: rgba(255, 255, 255, 0.9);
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      top: 4px;
      right: 4px;
      bottom: 4px;
      left: 4px;
      opacity: 0;
      @include transition;
    }

    .btn-overlay {
      // height: 30px;
      margin-bottom: 5px;
      // border-radius: 15px;
      padding: 0 4px;
      font-size: 12px;
      text-transform: uppercase;
      background-color: #ffffff;
      border-left: 1px solid $border;
      border-bottom: 1px solid $border;
      color: #666666;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      cursor: pointer;
      @include transition;

      &:first-child {
        border-bottom-left-radius: 6px;
      }

      &:hover {
        color: $primary;
        &.del {
          color: $red;
        }
      }

      &.highlight {
        // background-color: $green;
      }

      .mdi-heart {
        color: red;
      }

      .mdi {
        font-size: 12px;
      }
    }
  }

  .thumbnail {
    width: 90px;
    height: 90px;
    margin-bottom: 6px;
  }
}

.search-more {
  padding: 6px 15px;
  text-align: center;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f9fb;
  color: #7a92a5;

  .mdi {
    margin-right: 10px;
    font-size: 24px;
  }
}

.btn-group {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 6px;
  height: 40px;

  div {
    height: 40px;
    flex: 1;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    cursor: pointer;
    font-weight: 500;
    font-size: 0.9em;

    &:last-child {
      border: none;
    }

    &:hover {
      background-color: $accent;
      color: white;
    }

    &:active {
      background-color: darken($accent, 10);
    }
  }
}

.map-pin {
  background-color: $red;
  width: 6px;
  height: 6px;
  border-radius: 3px;
}

.map-container {
  width: 200px;
  height: 150px;
}

.sort-container {
  border: 8px solid #f0f4f7;
  background-color: white;
  padding: 18px 24px;
  border-radius: 8px;
  margin-top: 30px;
}

.reorder-container {
  // display: flex;
  // flex-direction: row;
  // height: 90px;
  // padding: 6px 12px 6px 4px;
  // margin: 4px 0;
  // border-radius: 6px;
  // align-items: center;
  // background-color: $artic;
  //
  // .drag-handle {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   height: 90px;
  //   cursor: grab;
  // }
  //
  // .mdi {
  //   font-size: 24px;
  //   color: #bbbbbb;
  //   margin-right: 8px;
  // }
  //
  // img {
  //   width: 100px;
  //   height: 90px;
  //   margin-right: 16px;
  //   object-fit: contain;
  // }
  //
  // .product {
  //   height: 60px;
  //   width: auto;
  //   border-radius: 6px;
  // }
  //
  // .name {
  //   text-align: left;
  //   font-size: 14px;
  //   font-weight: 500;
  // }

  .dropdownContainer,
  .containerInput {
    margin-bottom: 0;
    flex: 1;
    margin-right: 8px;

    .mdi {
      margin-right: 0 !important;
    }
  }
}

.grid-sort-container {
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  grid-gap: 0.5rem;
}

.reorder-container {
  display: flex;
  flex-direction: column;
  padding: 4px;
  // margin: 4px;
  border-radius: 6px;
  align-items: center;
  background-color: white;
  border: 1px solid #eee;
  // width: 100px;
  height: inherit !important;
  text-align: center;
  position: relative;

  &.row-layout {
    flex-direction: row;
    margin: 8px 0;
    padding: 6px 12px 6px 4px;
    width: inherit;
    min-height: 90px !important;
    text-align: left;

    img {
      width: 100px;
      height: 90px;
      margin-right: 16px;
      object-fit: contain;
    }

    .mdi {
      font-size: 24px;
      color: #bbbbbb;
      margin-right: 8px;
    }

    .drag-handle {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90px;
      cursor: grab;
    }
  }

  &:hover {
    cursor: grab;
    border: 1px solid $primary;
  }

  .product {
    width: 75px;
    border-radius: 4px;
    margin-right: 0;
    max-height: 100%;
    height: 75px;
    object-fit: contain;
  }

  .name {
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    padding: 4px;
    line-height: 130%;
    margin-top: 4px;
  }
}

.reorder-btn-container {
  flex-direction: row;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 20px;

  a {
    font-size: 0.9rem;
    color: $green;
    border: 1px solid $green;
    height: 30px;
    min-width: 100px;
    padding: 0 24px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $green;
      color: white;
    }
  }

  &.center {
    justify-content: center;
    padding: 16px 0;

    .btn {
      margin: 0 8px;
    }
  }
}

.action-container {
  display: flex;

  .btn-link {
    min-width: 40px;
    padding: 0 8px;
    height: 35px;
    background-color: white;
    border: 1px solid $border;
    display: inline-flex;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    cursor: pointer;

    .mdi {
      font-size: 18px;
      color: #555;
    }

    span {
      font-size: 0.8em;
      font-weight: 500;
      line-height: 100%;
      display: flex;
      color: #6f6f6f;
      text-transform: uppercase;
      margin-left: 5px;
    }

    &:hover {
      background-color: $primary;

      .mdi,
      span {
        color: white;
      }
    }
  }

  .btn-action {
    min-width: 40px;
    padding: 0 8px;
    height: 35px;
    background-color: white;
    border: 1px solid $border;
    display: inline-flex;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    cursor: pointer;
    span {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1;
      display: flex;
      color: #6f6f6f;
      text-transform: uppercase;
    }

    &:hover {
      background-color: $primary;
      span {
        color: #fff;
      }
    }
  }
}

.product-search-container {
  position: relative;
}

.product-row {
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 6px;
  position: absolute;
  left: 0;
  right: 0;
  top: -6px;
  max-height: 250px;
  overflow: scroll;
  z-index: 1;
  background-color: white;
  @include transition;
  @include card(1);

  li {
    margin: 0;
    padding: 12px;
    border-bottom: 1px solid $artic;
    cursor: pointer;
    font-size: 0.9rem;
    @include transition;

    &:hover {
      background-color: $artic;
    }
  }
}

.btn-close {
  width: 24px;
  height: 24px;

  background-color: $red;
  display: flex;
  line-height: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;

  @include transition;

  &:hover {
    background-color: darken($red, 20);
  }

  .mdi {
    color: white !important;
    font-size: 14px !important;
    margin-right: 0 !important;
  }
}

.date-start {
  min-width: 150px;
  font-size: 0.9em;
}

.date-end {
  min-width: 150px;
  font-size: 0.9em;
  color: #666666;
}

.color-swatch {
  .color {
    width: 36px;
    height: 26px;
    border-radius: 6px;
    background-color: red;
    margin: 5px;
    border: 1px solid #eee;
  }

  .swatch {
    height: 40px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid $border;
    // margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .swatch-title {
      font-size: 0.8rem;
      padding-left: 6px;
      text-transform: uppercase;
      font-weight: 500;
      color: $text;
    }
  }

  .popover {
    position: absolute;
    z-index: 10;
  }

  .cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.drag-home-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: grab;
  background-color: lighten($primary, 50);
  padding: 8px;
  border: 1px solid $primary;
  border-radius: 4px;
  position: relative;
  @include transition;

  .btn-bg {
    width: 66px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    overflow: hidden;
    @include transparent-bg;

    .mdi {
      font-size: 28px;
      color: rgba(0, 0, 0, 0.5);
    }

    img {
      width: 66px;
      height: 66px;
      object-fit: contain;
    }
  }

  h5 {
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 4px;
    margin-bottom: 0;
    text-align: center;
    line-height: 110%;
    word-break: break-word;
  }

  .btn-action-container {
    position: absolute;
    top: 0;
    right: 0;
  }

  .btn-overlay {
    margin-bottom: 5px;
    padding: 0 4px;
    font-size: 12px;
    text-transform: uppercase;
    background-color: #ffffff;
    border-top-right-radius: 4px;
    border-left: 1px solid $border;
    border-bottom: 1px solid $border;
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    @include transition;

    &:first-child {
      border-bottom-left-radius: 6px;
    }

    &.del {
      background-color: $red;
      color: #fff;

      &:hover {
        background-color: darken($red, 15);
      }
    }

    .mdi {
      font-size: 12px;
    }
  }
}

.inputDropdownLabel {
  margin-bottom: 4px;

  font-size: 0.95em;
  color: #888;
}

.file-input {
  border-radius: 6px;
  background-color: $artic;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  padding: 0 12px;
  cursor: pointer;

  label {
    margin: 0;
    text-transform: inherit;
    text-align: center;
    color: #666;
    font-size: 0.9em;
    font-weight: 500;
    flex: 1;
  }

  .csv-input {
    font-size: 0.9em;

    &:focus {
      outline: none;
    }
  }
}

.hp-datetime {
  border: 1px solid $border;
  height: 44px;
  padding-left: 8px;
  line-height: 100%;
  border-radius: 6px;
  background-color: #fff;

  .react-datetime-picker__inputGroup {
    input,
    select {
      &:focus {
        outline: none;
      }
    }
  }

  .react-datetime-picker__wrapper {
    border: none;
  }
  .react-datetime-picker__button {
    // background-color: red;
    padding: 0 8px;
    border-left: 1px solid $border;

    .mdi {
      font-size: 20px;
      color: #888;
    }
  }

  .react-calendar {
    border: none;
    border-radius: 6px;
    font-family: 'Roboto', sans-serif;
    @include card(1);
  }

  .react-datetime-picker__clock {
    border: none;
    border-radius: 6px;
    @include card(1);
  }
}

.btn-dropdown {
  display: inline-block;
  position: relative;
  text-transform: initial !important;
  font-size: 1rem !important;
  font-weight: 400 !important;

  .dd-button {
    display: inline-block;
    border: 1px solid $primary;
    color: #fff;
    border-radius: 6px;
    padding: 8px 30px 8px 20px;
    background-color: $primary;
    cursor: pointer;
    white-space: nowrap;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #fff;
    }

    &:hover {
      background-color: darken($primary, 5);
    }
  }

  .dd-input {
    display: none;
  }

  .dd-input + .dd-menu {
    display: none;
  }

  .dd-input:checked + .dd-menu {
    display: block;
  }

  .dd-menu {
    position: absolute;
    top: 100%;
    z-index: 5;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    color: #1a1a1a !important;
    overflow: hidden;
    right: 0;
    min-width: 210px;

    .menu-item {
      padding: 10px 20px;
      cursor: pointer;
      white-space: nowrap;
      border-bottom: 1px solid $border;

      &.divider {
        padding: 0;
        border-bottom: 1px solid #cccccc;
      }

      a {
        display: block;
        margin: -10px -20px;
        padding: 10px 20px;
      }

      &:hover {
        background-color: #f6f6f6;
      }
    }
  }
}

.tab-cluster {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  background-color: rgb(245, 245, 245);
  margin: -30px -30px 0;
  padding: 6px 30px;
  border-bottom: 1px solid $border;
  position: sticky;
  top: 0;
  z-index: 2000;

  &.alt {
    background-color: rgb(240, 240, 240);
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 0.9rem;
    margin: 0 0.8rem 0 0;
    line-height: 1;
    font-weight: 500;
  }

  .tab {
    padding: 6px 12px;
    font-size: 0.8em;
    line-height: 100%;
    border: 1px solid $border;
    margin: 2px;
    min-width: 90px;
    border-radius: 3px;
    background-color: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #eceff1;
    }

    &.retailer {
      img {
        height: 20px;
      }
    }

    &.active {
      background-color: #e1f5fe;
      border: 1px solid #b3e5fc;
      color: #007bff;
      cursor: default;
    }

    &.positive {
      background-color: $green;
      color: #ffffff;
    }

    &.negative {
      background-color: $red;
      color: #ffffff;
    }
  }

  .right {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
  }

  @include sm {
    margin: 0 -15px;
    padding: 6px 15px;
  }

  .inline {
    display: flex;
    flex: 1;

    .DateRangePickerInput {
      background-color: #fff !important;
      height: 42px;
    }

    .column {
      flex: 1;
    }

    label {
      flex: 0.2;
      text-align: right;
      margin-right: 24px;
      line-height: 120%;
      font-size: 0.8rem;
      text-transform: uppercase;
      font-weight: 500;
      margin-top: 0.65rem;
      color: $primary;
    }
  }

  .grid {
    width: 100%;
    gap: 12px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 8px;

    .DateRangePickerInput {
      background-color: #fff !important;
      height: 42px;
    }

    label {
      flex: 0.2;
      text-align: right;
      margin-right: 24px;
      line-height: 120%;
      font-size: 0.8rem;
      text-transform: uppercase;
      font-weight: 500;
      color: $primary;
    }
  }
}

.slot-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 80px);
  grid-gap: 0.5rem;
  justify-content: space-between;
}

.slot {
  width: 60px;
  // margin: 8px;

  .slot-item {
    border: 2px solid darken($artic, 5);
    overflow: hidden;
    border-radius: 10px;
    height: 70px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 4px;
    cursor: pointer;
    position: relative;

    &:hover {
      border: 2px solid darken($artic, 15);
    }

    .thumbnail {
      position: absolute;
      top: 2px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
    }

    img {
      width: 40px;
      height: 40px;
      display: block;
    }

    .indicator {
      font-weight: 700;
      position: absolute;
      top: 0;
      left: 4px;
      font-size: 1.1em;
      color: #00c853;
    }
  }

  .slot-point {
    border-top: 2px solid darken($artic, 5);
    background-color: $artic;
    flex: 1;
    text-align: center;
    font-weight: 600;
    font-size: 0.85em;
  }

  .slot-label {
    text-align: center;
    font-weight: 700;
    font-size: 0.9em;
    line-height: 100%;
  }
  .slot-sublabel {
    text-align: center;
    font-weight: 500;
    font-size: 0.8em;
    line-height: 100%;
  }
}

.select-single {
  font-size: 0.9rem;
  text-transform: capitalize;
}

.select-create {
  font-size: 0.9rem;
  text-transform: capitalize;
}

.hp-select__menu {
  z-index: 10 !important;
}

.aff-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.btn-affiliate {
  // width: 40px;
  height: 34px;
  padding: 0 12px;
  color: #fff;
  background-color: #00c853;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  line-height: 1;
  text-align: center;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;

  &:hover {
    background-color: darken(#00c853, 10);
  }

  .mdi {
    display: block;
    font-size: 20px;
    margin-right: 8px;
  }
}

.products-all {
  background-color: $artic;
  margin: 0 -24px -24px;
  padding: 16px 24px 24px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 1px solid darken($artic, 5);
}

.btn-container {
  &.remove {
    padding: 8px;
    display: grid;
    place-items: center;
  }

  &.end {
    display: flex;
    justify-content: flex-end;
  }
}

.alpha-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  gap: 6px;

  .alpha-item {
    border: 1px solid #eee;
    padding: 4px 8px;
    border-radius: 6px;
    line-height: 1;
    cursor: pointer;

    span {
      color: #555;
      font-size: 0.85rem;
      &:first-child {
        color: #1a1a1a;
        font-weight: 800;
        margin-right: 6px;
        padding-right: 6px;
        border-right: 1px solid #eee;
      }
    }

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

details {
  padding: 0.7rem 1rem;
  border: 1px solid $border;
  border-radius: 6px;
  margin-bottom: 8px;

  &[open] {
    > summary + * {
      margin-top: 0;
    }

    > summary {
      margin-bottom: 0.5rem;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }
}

summary {
  cursor: pointer;
  font-weight: bold;
  padding: 0.7rem 1rem;
  margin: -0.7rem -1rem;
  word-break: break-all;
}

.card-container-alt {
  background-color: rgb(245, 245, 245);
  margin: -30px -30px 0 -30px;
  padding: 30px;

  @include sm {
    padding: 15px;
    margin: -15px -15px 0 -15px;
  }
}

.card-footer {
  border-top: 3px solid $grayLight;
  line-height: 100%;
  padding: 16px 30px;
  margin: 0 -30px -30px -30px;
  display: flex;
  justify-content: flex-end;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #fff;
  z-index: 1;

  .footer-btn-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    [class^='Button_btn'] {
      margin-left: 8px;
    }

    .del-container {
      flex: 1;

      button {
        margin-left: 0;

        .mdi-delete {
          font-size: 24px;
          margin-right: 10px;
          color: #94a5b0;
          transition: all 0.25s ease;
        }

        &:hover {
          .mdi-delete {
            color: $red;
          }
        }
      }
    }
  }
}

.custom-select-container {
  height: 34px;
  flex: 1;
  font-size: 0.95rem;
  font-family: inherit;

  .custom-select__control {
    border-radius: 6px;
    min-height: 34px;
  }

  .custom-select__value-container {
    padding: 0 8px;
    height: 34px;
  }

  .custom-select__indicator {
    padding: 6px;
  }
  .custom-select__menu {
    color: #111;
  }
}

.custom-select {
}
