.badge-category {
  display: flex;
  line-height: normal;
  align-items: center;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  .btn-delete {
    background-color: $red;
    width: 20px;
    height: 20px;
    border-radius: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    cursor: pointer;
    @include transition;

    &:hover {
      background-color: darken($red, 20);
    }
    &:active {
      @include scale(0.9);
    }

    .mdi {
      font-size: 14px;
    }
  }

  span {
    font-size: 0.9rem;
  }
}

.badge-retailer {
  display: flex;
  background-color: white;
  border: 2px solid $artic;
  padding: 5px 0 5px 10px;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  @include transition;

  &.selected {
    border: 2px solid $yellow;

    &:hover {
      border: 2px solid darken($yellow, 10);
    }
  }

  &:hover {
    border: 2px solid darken($artic, 20);
  }

  .logo {
    height: 28px;
    img {
      max-height: 100%;
    }
  }

  .btn-delete {
    width: 30px;
    height: 30px;
    border-left: 1px solid $artic;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: darken($gray, 30);

    &:hover {
      .mdi {
        color: $red;
      }
    }

    .mdi {
      font-size: 18px;
    }
  }
}

%status {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

.ACTIVE {
  @extend %status;
  color: $greenDark;
}
.INACTIVE {
  @extend %status;
  color: darken($yellow, 15);
}
.EXPIRED {
  @extend %status;
  color: #bbbbbb;
}
.UPCOMING {
  @extend %status;
  color: #7b1fa2;
}
.PREVIEW {
  @extend %status;
  color: #437dff;
}

%badge {
  display: inline-block;
  font-size: 0.8em;
  font-weight: 600;
  color: white;
  border-radius: 6px;
  padding: 4px 6px;
  line-height: 100%;
  text-transform: uppercase;
}

.badge-pending {
  @extend %badge;
  background-color: $amber;
}

.badge-new {
  @extend %badge;
  background-color: $red;
}

.badge-replied {
  @extend %badge;
  background-color: $green;
}
