.react-dropdown-tree-select {
  .dropdown {
    .dropdown-trigger {
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
      min-height: 40px !important;
      border-radius: 6px !important;
      background-color: white;
      line-height: 100% !important;

      &.arrow.bottom:after {
      }

      &.arrow {
        &.bottom {
          &:after {
            font-family: 'Material Design Icons';
            font-size: 18px;
            content: '\F0140' !important;
          }
        }

        &.top {
          &:after {
            font-family: 'Material Design Icons';
            font-size: 18px;
            content: '\F0143' !important;
          }
        }
      }
    }

    .tag {
      font-size: 0.95em;
      padding: 4px;
      border-radius: 3px;
      background-color: #e1f5fe;
      border: 1px solid darken(#e1f5fe, 5);

      .tag-remove {
        font-size: 100%;
        margin-left: 4px;
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .tag-item {
      .search {
        border-bottom: none;
      }
    }
  }
}

.rc-cascader-menu {
  width: 220px !important;
}

.rc-cascader-menus {
  font-size: 0.9em !important;
  z-index: 1000;
}

// Markdown Editor
.editor-preview {
  ul,
  ol {
    padding: inherit;
    margin-left: 10px;
  }
}

// react-datetime
.rdt {
  display: flex;
  input {
    display: flex;
    flex: 1;
    height: 40px;
    text-align: center;
    border-radius: 6px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .rdtPicker {
    margin-top: 40px;
    min-width: 220px;
    font-size: 0.9rem;
  }
}

.tagify {
  height: 40px;
  border-radius: 6px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 12px;
  font-size: 0.95em;
  outline: none;
  margin-bottom: 0.8em;
}

.html-editor {
  min-height: 200px;
  max-height: 500px;
  overflow: scroll;
  font-family: 'Roboto', sans-serif;

  div[class*='RichTextEditor__paragraph'] {
    margin: 0;
    margin-bottom: 0.7rem;
  }
  span[class*='ImageSpan__root'] {
    display: block !important;
    margin: auto !important;
    border-radius: 6px !important;
  }

  ul,
  ol {
    padding-left: 1.5rem;
    margin: 0;
    li {
      margin-bottom: 0.6rem;
    }
  }

  h2 {
    font-weight: 800;
    margin: 1.5rem 0 0.5rem;
    font-size: 1.8rem;
    color: #1a1a1a;
    span {
      font-weight: 800 !important;
    }
  }

  h3 {
    font-weight: 500 !important;
    font-size: 1.2rem !important;
    margin: 1.5rem 0 0.5rem !important;
    color: rgba(0, 0, 0, 0.9) !important;
    border-bottom: none !important;
    padding: 0 !important;
    text-align: initial !important;
    text-transform: initial !important;

    span {
      font-weight: inherit !important;
      font-size: inherit !important;
      color: inherit !important;
      padding-left: 0 !important;
    }
  }

  h4 {
    font-size: 1.1rem;
    font-weight: 500;
    color: #0559ce;
    margin-bottom: 0;
  }

  h5 {
    font-size: 1rem;
    margin-top: 1rem;
  }
}

// QUILL Component
.ql-editor {
  font-size: 15px;
  min-height: 200px;

  p {
    margin-bottom: 8px !important;
  }

  img {
    display: flex;
    margin: auto;
  }
  ol,
  ul {
    margin-top: 8px !important;
    margin-bottom: 12px !important;
  }
  li {
    margin-bottom: 2px;
  }
}
