.dropzone {
  border: 2px dashed $grayLight;
  background-color: #eee;
  border-radius: 8px;
  min-height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  @include transition;
  @include transparent-bg;

  &.mini {
    min-height: unset;
  }

  &:focus {
    outline: none;
  }

  .mdi {
    font-size: 48px;
    color: darken($grayLight, 60);
    line-height: 100%;
  }

  .info {
    text-align: center;

    h3,
    h4 {
      color: darken($grayLight, 60) !important;
      text-align: center !important;
      margin: 0 0 4px !important;
      padding: inherit !important;
      font-size: 1.1rem !important;
      font-weight: 600 !important;
      line-height: 110% !important;
    }
    p {
      font-size: 0.85rem;
      line-height: 110%;
      color: darken($grayLight, 60);
    }
  }

  .dropzone-preview-container {
    text-align: center;
  }

  .dropzone-preview {
    max-width: 100%;
    max-height: 200px;
  }

  &:hover {
    border: 2px solid lighten($primary, 30);
  }
}
.dropzone-active {
  border: 2px solid $green;
  border-radius: 8px;
  min-height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;

  .mdi {
    font-size: 48px;
    color: darken($green, 5);
    line-height: 100%;
  }

  .info {
    text-align: center;

    h3 {
      color: darken($green, 10);
      margin: 0;
      font-size: 1.1rem;
      font-weight: 600;
    }
    p {
      font-size: 0.85rem;
      line-height: 110%;
      color: darken($green, 5);
    }
  }
}

.dropzone-warning {
  text-align: center;
  font-size: 0.85rem;
  color: #f44336;
  font-weight: 500;
  margin-top: 8px;
  animation: blinker 1250ms linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
