@import 'Themes/scss/mixins';
@import 'Themes/scss/variables';
@import 'Themes/scss/colors';
@import 'Themes/scss/normalize/import-now';

@import 'Themes/scss/base';
@import 'Themes/scss/header';
@import 'Themes/scss/layout';
@import 'Themes/scss/table';
@import 'Themes/scss/breadcrumb';
@import 'Themes/scss/autosuggest';
@import 'Themes/scss/accordion';
@import 'Themes/scss/form';
@import 'Themes/scss/dropzone';
@import 'Themes/scss/checkbox';
@import 'Themes/scss/datepicker';
@import 'Themes/scss/divider';
@import 'Themes/scss/badge';
@import 'Themes/scss/components';
@import 'Themes/scss/dragdrop';
@import 'Themes/scss/radio';
@import 'Themes/scss/modal';
@import 'Themes/scss/dropdown';
@import 'Themes/scss/drawer';

@import 'Themes/scss/overwrites';
@import 'Themes/scss/helper';

// FIX: react-responsive-modal, the z-index somehow is infront of the "cascader" (antd component, inside things like AppNavpoint, where we can
// choose categories for example). So we just make the cascader infront of everything.
.rc-cascader-menus, .rc-y-menu, .rc-cascader-menu-item, .rc-cascader-menu-item-expand {
    z-index: 99999999 !important;
}