$color-signal: $accent;
$color-light: #c1c7cd;
$color-muted: #f7f9fa;

%input-hide {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  padding: 0;
}

%input-box-base {
  content: '';
  position: absolute;
  display: inline-flex;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
}

.checkbox-group {
  margin-top: 10px;

  label {
    display: flex;
    font-size: 0.9rem;
    margin-bottom: 0.8em;
    align-items: center;
    color: $text;
    font-weight: 500;
    text-transform: capitalize;
    cursor: pointer;
  }

  &.withMargin {
    label {
      margin-bottom: 0.4rem;
    }
  }
}
.input {
  $class: &;
  display: flex;
  font-size: 0.9rem;
  margin-bottom: 0.8em;
  align-items: center;
  cursor: pointer;

  &__field {
    @extend %input-hide;

    &:focus + #{$class}__text:before {
      border-color: $color-signal;
    }

    &[disabled] + #{$class}__text {
      opacity: 0.5;
    }

    &:hover {
      & + #{$class}__text:before {
        border-color: darken($color-muted, 40);
      }
    }

    &:active {
      & + #{$class}__text:before {
        border-width: 3px;
        border-color: darken($color-muted, 20);
      }
    }

    &:checked {
      &:active + #{$class}__text:before {
        background-color: darken($color-signal, 8);
      }
      & + #{$class}__text:before {
        background-color: $color-signal;
        border-color: transparent;
      }
      &:active + #{$class}__text:after {
        transform: translate3d(75%, -6%, 0) rotate(45deg) scale(0.5);
      }
      & + #{$class}__text:after {
        transform: translate3d(75%, -6%, 0) rotate(45deg) scale(0.7);
      }
    }
  }

  &__text {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 2em;
    line-height: 1;

    &:before {
      @extend %input-box-base;
      background-color: #fff;
      width: 1.3em;
      height: 1.3em;
      border: 2px solid darken($color-muted, 20);
      border-radius: 0.2em;
    }

    &:after {
      @extend %input-box-base;
      width: 0.5em;
      height: 1em;
      background-color: transparent;
      border-bottom: 3px solid white;
      border-right: 3px solid white;
      transform: translate3d(75%, -6%, 0) rotate(45deg) scale(0);
    }
  }
}
